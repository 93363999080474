import { Navigate, Outlet } from 'react-router-dom';

import { ModuleSettingsSelector, useSelector } from '../../store';

export function EnableProfilesOutlet() {
  const enableProfiles = useSelector(
    ModuleSettingsSelector.selectEnableProfiles,
  );

  if (enableProfiles) {
    return <Outlet />;
  }

  return <Navigate to="/insights" />;
}

export function EnableJobsOutlet() {
  const enableJobs = useSelector(ModuleSettingsSelector.selectEnableJobs);

  if (enableJobs) {
    return <Outlet />;
  }

  return <Navigate to="/insights" />;
}

export function EnableConnectOutlet() {
  const enableConnect = useSelector(ModuleSettingsSelector.selectEnableConnect);

  if (enableConnect) {
    return <Outlet />;
  }

  return <Navigate to="/insights" />;
}

export function EnableCompanyAdsOutlet() {
  const enableCompanyAds = useSelector(
    ModuleSettingsSelector.selectEnableCompanyAds,
  );

  if (enableCompanyAds) {
    return <Outlet />;
  }

  return <Navigate to="/insights" />;
}

export function EnableOffersOutlet() {
  const enableOffers = useSelector(ModuleSettingsSelector.selectEnableOffers);

  if (enableOffers) {
    return <Outlet />;
  }

  return <Navigate to="/insights" />;
}
