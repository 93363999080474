import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ControlledInput,
  ControlledSelect,
  InnerPaperContentContainer,
  InnerPaperContentContainerSection,
  InnerPaperContentContainerSectionsContainer,
  OrbiLoader,
  PaperContentContainer,
  Text,
  flattenFieldErrorsObject,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { NotOnboardedToolbar, UploadLogo } from '../../../components';
import {
  COMPANY_NAME_MAX_LENGTH,
  CreateCompanyForm,
  CreateCompanyValidation,
  EmployeeCount,
  Industry,
  Option,
  employeeCountList,
  formatIndustry,
  industryList,
} from '../../../models';
import { Logger } from '../../../services';
import {
  CompanyInvitationSelector,
  CreateCompanySelector,
  createCompanyThunk,
  useDispatch,
  useSelector,
} from '../../../store';

const EMPLOYEE_COUNT_OPTIONS: Option<EmployeeCount>[] = employeeCountList.map(
  (employeeCount) => ({
    value: employeeCount,
    text: employeeCount,
  }),
);

const INDUSTRY_OPTIONS: Option<Industry>[] = industryList.map((industry) => ({
  value: industry,
  text: formatIndustry(industry),
}));

function OnboardingContent() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const createCompanyStatus = useSelector(CreateCompanySelector.selectStatus);

  const form = useForm<CreateCompanyForm>({
    resolver: joiResolver(CreateCompanyValidation),
    defaultValues: {
      employeeCount: undefined,
      industry: undefined,
      name: '',
    },
  });

  const submit = form.handleSubmit(
    async (form) => {
      const res = await dispatch(createCompanyThunk(form));
      if (res.meta.requestStatus !== 'fulfilled') return;

      navigate('/invite-team-members', { replace: true });
    },
    (err) => {
      Logger.warning('createCompany Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    form.setFocus('name');
  }, [form]);

  return (
    <Box flex flexDirection="column" gap={32}>
      <Text
        color="onboardingHeaderTextColor"
        tx="title.create-company.dashboard"
        variant="titleMd"
        as="h1"
        textAlign="center"
      />

      <FormProvider {...form}>
        <Box flexWrap flex gap={16}>
          <Box width={256} height={256}>
            <UploadLogo width={256} height={256} name="logo" />
          </Box>

          <Box
            flex
            flexDirection="column"
            gap={8}
            flexGrow={1}
            flexJustify="center"
          >
            <ControlledInput
              autoFocus
              labelTx="label.company-settings.name.label"
              maxLength={COMPANY_NAME_MAX_LENGTH}
              name="name"
              required
            />

            <ControlledSelect
              width="100%"
              labelTx="label.company-settings.industry.label"
              name="industry"
              options={INDUSTRY_OPTIONS}
              required
            />

            <ControlledSelect
              width="100%"
              labelTx="label.company-settings.employees.label"
              name="employeeCount"
              options={EMPLOYEE_COUNT_OPTIONS}
              required
              mt={32}
            />

            <Button
              mt={32}
              width="100%"
              onClick={submit}
              variant="primary"
              tx="button.continue"
              isLoading={createCompanyStatus === 'pending'}
              large
            />
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
}

export function Onboarding() {
  const companyInvitation = useSelector(CompanyInvitationSelector.selectData);
  const createCompanyStatus = useSelector(CreateCompanySelector.selectStatus);

  if (!companyInvitation) {
    return <Navigate to="/" />;
  }

  if (createCompanyStatus === 'completed') {
    return <OrbiLoader />;
  }

  return (
    <React.Fragment>
      <NotOnboardedToolbar />

      <PaperContentContainer>
        <InnerPaperContentContainer width={680} my="auto">
          <InnerPaperContentContainerSectionsContainer>
            <InnerPaperContentContainerSection p={32}>
              <OnboardingContent />
            </InnerPaperContentContainerSection>
          </InnerPaperContentContainerSectionsContainer>
        </InnerPaperContentContainer>
      </PaperContentContainer>
    </React.Fragment>
  );
}
