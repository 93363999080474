import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ControlledInput,
  Form,
  IconButton,
  ResetPasswordForm,
  SplitScreenContentContainer,
  Text,
  TrailingInputBox,
} from '@orbiapp/components';
import React from 'react';
import { useForm } from 'react-hook-form';

import { SignedOutToolbar } from '../../../components';
import { ResetPasswordValidation } from '../../../models';
import {
  AuthStateSelector,
  resetPasswordThunk,
  useDispatch,
  useSelector,
} from '../../../store';

function ResetPasswordContent() {
  const dispatch = useDispatch();

  const authStatus = useSelector(AuthStateSelector.selectStatus);

  const form = useForm<ResetPasswordForm>({
    defaultValues: { email: '' },
    resolver: joiResolver(ResetPasswordValidation),
  });

  const isLoading = authStatus === 'pending';

  const resetPassword = form.handleSubmit((data) => {
    dispatch(resetPasswordThunk(data.email));
  });

  const email = form.watch('email');

  const clearEmail = () => {
    form.setValue('email', '');
    form.setFocus('email');
  };

  React.useEffect(() => {
    form.setFocus('email');
  }, [form]);

  return (
    <React.Fragment>
      <Box
        flex
        flexAlign="center"
        flexDirection="column"
        gap={16}
        textAlign="center"
      >
        <Text
          variant="titleMd"
          tx="label.auth.reset-password.title"
          color="signedOutPagesTitleColor"
        />
        <Text
          variant="bodyMd"
          tx="label.auth.reset-password.subtitle"
          color="signedOutPagesTextColor"
        />
      </Box>
      <Form
        flex
        flexDirection="column"
        formMethods={form}
        width="100%"
        gap={32}
        onSubmit={resetPassword}
      >
        <ControlledInput
          disabled={isLoading}
          trailingElement={
            <TrailingInputBox>
              {email && (
                <IconButton icon="x-circle-outline" onClick={clearEmail} />
              )}
            </TrailingInputBox>
          }
          labelTx="label.auth.email"
          name="email"
        />

        <Button
          isLoading={isLoading}
          width="100%"
          tx="button.auth.reset-password"
          type="submit"
          variant="primary"
          large
        />
      </Form>
    </React.Fragment>
  );
}

export function ResetPassword() {
  return (
    <React.Fragment>
      <SignedOutToolbar />

      <SplitScreenContentContainer>
        <ResetPasswordContent />
      </SplitScreenContentContainer>
    </React.Fragment>
  );
}
