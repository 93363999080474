import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
} from '@orbiapp/components';

import {
  CreateOfferForm,
  CreatedOffer,
  Offer,
  OfferCategory,
  OffersOrderByKey,
  PartialOffer,
  UpdateOfferForm,
  UpdatedOffer,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';
import {
  CreatedOfferDto,
  OfferCategoryDto,
  OfferDto,
  PartialOfferDto,
} from './offers.dto';
import {
  createOfferMapper,
  offerCategoryMapper,
  offerMapper,
  partialOfferMapper,
} from './offers.mappers';

export async function createOffer(
  createOffer: CreateOfferForm,
): Promise<GeneralApiResultWithData<CreatedOffer>> {
  const res = await apisauce.post<CreatedOfferDto, any>(
    '/v1/offers',
    createOfferMapper(createOffer),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = {
      coverImage: res.data.coverImage,
      offerKey: res.data.offerKey,
    };

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createOffer', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function updateOffer(params: {
  updateOffer: UpdateOfferForm;
  offerKey: string;
}): Promise<GeneralApiResultWithData<UpdatedOffer>> {
  const res = await apisauce.patch<UpdatedOffer, any>(
    `/v1/offers/${params.offerKey}`,
    params.updateOffer,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = {
      coverImage: res.data.coverImage,
    };

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('updateOffer', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function toggleIsDisabled(params: {
  isDisabled: boolean;
  offerKey: string;
}): Promise<GeneralApiResult> {
  const res = await apisauce.patch<void, any>(`/v1/offers/${params.offerKey}`, {
    isDisabled: params.isDisabled,
  });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}

export async function getOffers(params: {
  pagination: Pagination<OffersOrderByKey>;
  search?: string;
}): Promise<GeneralApiResultWithData<PartialOffer[]>> {
  const { pagination, search } = params;

  const res = await apisauce.get<PartialOfferDto[], any>('/v1/offers', {
    ...pagination,
    search: search?.length ? search : undefined,
  });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(partialOfferMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getOffers', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function getOffer(
  offerKey: string,
): Promise<GeneralApiResultWithData<Offer>> {
  const res = await apisauce.get<OfferDto, any>(`/v1/offers/${offerKey}`);

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = offerMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getOffer', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function getOfferCategories(): Promise<
  GeneralApiResultWithData<OfferCategory[]>
> {
  const res = await apisauce.get<OfferCategoryDto[], any>(
    '/v1/offers/categories',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(offerCategoryMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getOfferCategories', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}
