import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
  constructQueryURL,
} from '@orbiapp/components';

import {
  PartialTalentPoolUser,
  PartialTalentPoolUserOrderBy,
  TalentPoolUser,
  TalentPoolUserStats,
  UpdateTalentPoolUser,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import {
  PartialTalentPoolUserDto,
  TalentPoolUserDto,
  TalentPoolUserStatsDto,
} from './talent-pool.dto';
import {
  partialTalentPoolUserMapper,
  talentPoolUserMapper,
  talentPoolUserStatsMapper,
  updateTalentPoolUserMapper,
} from './talent-pool.mappers';

export const getTalentPoolUserStats = async (): Promise<
  GeneralApiResultWithData<TalentPoolUserStats>
> => {
  const res = await apisauce.get<TalentPoolUserStatsDto, any>(
    '/v2/connect/talent-pool-users/stats',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = talentPoolUserStatsMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getTalentPoolStats', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getTalentPoolUser = async (
  userKey: string,
): Promise<GeneralApiResultWithData<TalentPoolUser>> => {
  const res = await apisauce.get<TalentPoolUserDto, any>(
    `/v2/connect/talent-pool-users/${userKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = talentPoolUserMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getTalentPoolUser', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getPartialTalentPoolUsers = async (params: {
  pagination: Pagination<PartialTalentPoolUserOrderBy>;
}): Promise<GeneralApiResultWithData<PartialTalentPoolUser[]>> => {
  const res = await apisauce.post<PartialTalentPoolUserDto[], any>(
    constructQueryURL(`/v2/connect/talent-pool-users`, params.pagination),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(partialTalentPoolUserMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getTalentPoolUsers', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateTalentPoolUser = async (
  updateTalentPoolUserParams: UpdateTalentPoolUser,
): Promise<GeneralApiResult> => {
  const res = await apisauce.patch<TalentPoolUserDto, any>(
    `/v2/connect/talent-pool-users/${updateTalentPoolUserParams.userKey}`,
    updateTalentPoolUserMapper(updateTalentPoolUserParams),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
