import { GeneralApiResultWithData, fromScu } from '@orbiapp/components';

import {
  GetTimeBasedInsights,
  InsightsItem,
  LiveInsights,
  TimeBasedInsights,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';
import {
  InsightsItemDto,
  LiveInsightsDto,
  TimeBasedInsightsDto,
} from './insights.dto';
import {
  getInsightsLiveDataMapper,
  getTimeSeriesInsightsMapper,
  insightsItemMapper,
  timeBasedInsightsMapper,
} from './insights.mappers';

export const getInsightsLiveData = async (): Promise<
  GeneralApiResultWithData<LiveInsights>
> => {
  const res = await apisauce.get<LiveInsightsDto, any>(
    '/v1/insights/data/live',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = getInsightsLiveDataMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getInsightsLiveData', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getInsightsItems = async (): Promise<
  GeneralApiResultWithData<InsightsItem[]>
> => {
  const res = await apisauce.get<InsightsItemDto[], any>('/v1/insights/items');

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(insightsItemMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getInsightsItems', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getTimeBasedInsights = async (
  params: GetTimeBasedInsights,
): Promise<GeneralApiResultWithData<TimeBasedInsights>> => {
  const res = await apisauce.post<TimeBasedInsightsDto, any>(
    '/v1/insights/data/time-based',
    getTimeSeriesInsightsMapper(params),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = timeBasedInsightsMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getTimeBasedInsights', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const exportInsightsData = async (
  params: GetTimeBasedInsights,
): Promise<GeneralApiResultWithData<Blob>> => {
  const res = await apisauce.post<Blob, any>(
    '/v1/insights/export',
    getTimeSeriesInsightsMapper(params),
    { responseType: 'blob' },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('exportInsightsData', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getEstimatedValue = async (): Promise<
  GeneralApiResultWithData<number>
> => {
  const res = await apisauce.get<{ value: number }, any>(
    '/v1/insights/estimated-value',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = fromScu(res.data.value);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('estimatedValue', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
