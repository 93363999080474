import { EditMeta } from '@orbiapp/components';

export interface CreateCompanyQr {
  name: string;
  description: string | null;
  startDate: number;
}

export interface UpdateCompanyQr {
  name?: string;
  description?: string;
  startDate?: number;
}

export interface CompanyQr {
  companyQrKey: string;
  createdAt: number;
  description: string | null;
  editMeta: EditMeta;
  endDate: number;
  link: string | null;
  name: string;
  scanCount: number;
  startDate: number;
  connectCount: number;
}

export interface PartialCompanyQr
  extends Pick<
    CompanyQr,
    | 'companyQrKey'
    | 'name'
    | 'createdAt'
    | 'startDate'
    | 'endDate'
    | 'scanCount'
    | 'connectCount'
  > {}

export type CompanyQrOrderBy = keyof Pick<
  PartialCompanyQr,
  'createdAt' | 'startDate' | 'name' | 'scanCount' | 'connectCount'
>;

export const companyQrSortableKeys: Record<CompanyQrOrderBy, CompanyQrOrderBy> =
  {
    createdAt: 'createdAt',
    startDate: 'startDate',
    name: 'name',
    scanCount: 'scanCount',
    connectCount: 'connectCount',
  };
