import { Box } from '../../box';
import { Paginator } from '../../paginator';
import { Text } from '../../text';
import { TinySelect, TinySelectOptionProps } from '../../tiny-select';

interface TablePaginationProps {
  tx: TxString;

  currentPage: number;

  hasPrevPage: boolean;
  hasNextPage: boolean;

  onPageSizeChange: (pageSize: number) => void;
  onPaginate: (page: number) => void;

  pageSize: number;

  paginatorOptions: TinySelectOptionProps<number>[];
}

export function TablePagination(props: TablePaginationProps) {
  const {
    tx,

    currentPage,

    hasPrevPage,
    hasNextPage,

    onPageSizeChange,
    onPaginate,

    pageSize,

    paginatorOptions,
  } = props;

  return (
    <Box flexJustify="end" gap={24} flexAlign="center" flex>
      <Box flex gap={16} flexAlign="center">
        <Text tx={tx} variant="bodySm" whiteSpace="nowrap" />

        <TinySelect
          invertMenu
          onChange={onPageSizeChange}
          options={paginatorOptions}
          value={pageSize}
        />
      </Box>

      <Paginator
        currentPage={currentPage}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        onPaginate={onPaginate}
      />
    </Box>
  );
}
