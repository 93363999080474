import {
  Box,
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  ContentContainer,
  Image,
  InnerContentContainer,
  InnerPageContainer,
  Link,
  PageContainer,
  ResponsiveBox,
  Status,
  StatusProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Text,
  paginatorOptions,
  parseTimestamp,
} from '@orbiapp/components';
import React from 'react';

import { assets } from '../../../assets';
import { CONTACT_CUSTOMER_SUCCESS_URL } from '../../../constants';
import { useDataGridPagination } from '../../../helpers';
import {
  CompanyAdPlacement,
  CompanyAdsOrderByKey,
  PartialCompanyAd,
  companyAdsSortableKeys,
} from '../../../models';
import {
  CompanyAdsSelector,
  companyAdsActions,
  getCompanyAdsThunk,
  useSelector,
} from '../../../store';

const COMPANY_ADS_BREADCRUMBS: BreadcrumbListItem[] = [
  { to: '/jobs', tx: 'label.breadcrumbs.company-ads.company-ads' },
];

const TABLE_COLUMN_WIDTHS = {
  title: 'auto',
  placement: 200,
  activeFrom: 200,
  status: 200,
  actions: 50,
};

interface BoosterCard {
  src: string;
  titleLeftTx: TxString;
  titleRightTx: TxString;
  descriptionTx: TxString;
}

const boosterCards: BoosterCard[] = [
  {
    src: assets.companyAdBoosters.event,
    titleLeftTx: 'label.company-ads.empty-state.cards.1.header-left',
    titleRightTx: 'label.company-ads.empty-state.cards.1.header-right',
    descriptionTx: 'label.company-ads.empty-state.cards.1.description',
  },
  {
    src: assets.companyAdBoosters.carrousel,
    titleLeftTx: 'label.company-ads.empty-state.cards.2.header-left',
    titleRightTx: 'label.company-ads.empty-state.cards.2.header-right',
    descriptionTx: 'label.company-ads.empty-state.cards.2.description',
  },
  {
    src: assets.companyAdBoosters.ticket,
    titleLeftTx: 'label.company-ads.empty-state.cards.3.header-left',
    titleRightTx: 'label.company-ads.empty-state.cards.3.header-right',
    descriptionTx: 'label.company-ads.empty-state.cards.3.description',
  },
];

const renderBoosterCard = (boosterCard: BoosterCard, index: number) => (
  <Box width={250} key={index} flex flexDirection="column">
    <Image width={175} height={157} src={boosterCard.src} alt="" />
    <Box flex gap={4} pt={16} pb={4}>
      <Text
        tx={boosterCard.titleLeftTx}
        variant="bodyMdBold"
        color="companyAdsEmptyTableStateTitleLeft"
      />
      <Text
        tx={boosterCard.titleRightTx}
        variant="bodyMdBold"
        color="companyAdsEmptyTableStateTitleRight"
      />
    </Box>
    <Text
      tx={boosterCard.descriptionTx}
      variant="bodyMd"
      color="companyAdsEmptyTableStateText"
    />
  </Box>
);

function getStatusProps(partialCompanyAd: PartialCompanyAd): StatusProps {
  if (
    partialCompanyAd.activeFrom > Date.now() &&
    partialCompanyAd.activeTo < Date.now()
  ) {
    return {
      variant: 'success',
      tx: 'label.product-status.live',
    };
  }

  return {
    variant: 'error',
    tx: 'label.product-status.past',
  };
}

function getCompanyAdTypeTx(placement: CompanyAdPlacement): TxString {
  switch (placement) {
    case 'carousel':
      return 'label.company-ads.carousel';

    case 'feed':
      return 'label.company-ads.feed';

    case 'ticket':
      return 'label.company-ads.ticket';
  }
}

function renderCompanyAdTableRow(
  partialCompanyAd: PartialCompanyAd,
  index: number,
) {
  return (
    <TableRow key={`company-ad-table-row-${index}`}>
      <TableCell
        width={TABLE_COLUMN_WIDTHS.title}
        text={partialCompanyAd.title}
      />
      <TableCell
        width={TABLE_COLUMN_WIDTHS.placement}
        tx={getCompanyAdTypeTx(partialCompanyAd.placement)}
      />
      <TableCell
        width={TABLE_COLUMN_WIDTHS.activeFrom}
        text={parseTimestamp(partialCompanyAd.activeFrom, 'DD MMM YYYY')}
      />
      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status {...getStatusProps(partialCompanyAd)} />
      </TableCell>

      <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight />
    </TableRow>
  );
}

function ComapnyAdsTableEmptyState() {
  const cards = boosterCards.map(renderBoosterCard);

  return (
    <Box flex gap={32} flexDirection="column" flexAlign="start">
      <ResponsiveBox
        sm={
          <Box flex gap={16}>
            {cards}
          </Box>
        }
      >
        <Box flex gap={32} flexDirection="column" pt={16}>
          {cards}
        </Box>
      </ResponsiveBox>

      <Link
        href={CONTACT_CUSTOMER_SUCCESS_URL}
        target="_blank"
        tx="label.connect.contact-sales"
      />
    </Box>
  );
}

function ComapnyAdsTable() {
  const companyAdsStatus = useSelector(CompanyAdsSelector.selectStatus);
  const companyAdsData = useSelector(CompanyAdsSelector.selectData);
  const companyAdsPagination = useSelector(CompanyAdsSelector.selectPagination);

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination<PartialCompanyAd, CompanyAdsOrderByKey>({
      data: companyAdsData,
      pagination: companyAdsPagination,
      reset: companyAdsActions.clearCompanyAds,
      thunk: getCompanyAdsThunk,
    });

  const isLoading = companyAdsStatus === 'pending';

  const isEmpty = rows.length === 0;

  if (isEmpty) {
    if (isLoading) {
      return null;
    }

    return <ComapnyAdsTableEmptyState />;
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={companyAdsPagination.orderBy}
          sortableColumns={Object.values(companyAdsSortableKeys)}
          sortOrder={companyAdsPagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.company-ads.name"
              name={companyAdsSortableKeys.title}
            />
            <TableHead
              tx="label.company-ads.type"
              name={companyAdsSortableKeys.placement}
            />
            <TableHead
              tx="label.company-ads.start-date"
              name={companyAdsSortableKeys.activeFrom}
            />
            <TableHead tx="label.company-ads.status" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>{rows.map(renderCompanyAdTableRow)}</TableBody>
      </Table>

      <TablePagination
        currentPage={paginatorProps.currentPage}
        hasNextPage={paginatorProps.hasNextPage}
        hasPrevPage={paginatorProps.hasPrevPage}
        onPageSizeChange={onPageSizeChange}
        onPaginate={onPaginate}
        pageSize={paginatorProps.pageSize}
        paginatorOptions={paginatorOptions}
        tx="label.general.rows-per-page"
      />
    </React.Fragment>
  );
}

export function CompanyAds() {
  return (
    <PageContainer>
      <BreadcrumbsToolbar breadcrumbListItems={COMPANY_ADS_BREADCRUMBS} />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <Text
              color="pageTitle"
              variant="titleMd"
              tx="title.company-ads.dashboard"
              as="h1"
            />

            <ComapnyAdsTable />
          </InnerContentContainer>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}
