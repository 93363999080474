import {
  EmulateUserSessionStorage,
  OrbiLoader,
  WorkspacesSessionStorage,
} from '@orbiapp/components';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Logger } from '../../../services';
import {
  AuthStateSelector,
  authActions,
  getAccountThunk,
  getAuthAccountThunk,
  getCompanyThunk,
  useDispatch,
  useSelector,
} from '../../../store';

export const EmulateUser = () => {
  Logger.addBreadcrumb('info', 'pages', 'EmulateUser');

  const params = useParams<{ userKey: string }>();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);
  const isAdmin = useSelector(AuthStateSelector.selectIsSuperAdmin);

  const emulateUser = React.useCallback(async () => {
    if (!authenticated || !params.userKey) {
      navigate('/sign-in', { replace: true });
      return;
    }

    WorkspacesSessionStorage.endSession();
    EmulateUserSessionStorage.startSession(params.userKey);

    Sentry.setTags({ isEmulating: true, isAdmin });

    dispatch(authActions.setIsEmulating(true));

    await dispatch(getAuthAccountThunk());
    await dispatch(getAccountThunk());
    await dispatch(getCompanyThunk());

    navigate('/insights');
  }, [authenticated, dispatch, isAdmin, navigate, params.userKey]);

  React.useEffect(() => {
    emulateUser();
  }, [emulateUser]);

  return <OrbiLoader />;
};
