import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
} from '@orbiapp/components';

import {
  CreateJobForm,
  Job,
  JobsFilterBy,
  JobsOrderByKey,
  PartialJob,
  UpdateJobForm,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';
import { JobDto, PartialJobDto } from './jobs.dto';
import {
  createJobMapper,
  jobMapper,
  partialJobMapper,
  updateJobMapper,
} from './jobs.mappers';

export async function createJob(
  createJobForm: CreateJobForm,
): Promise<GeneralApiResultWithData<string>> {
  const res = await apisauce.post<{ jobKey: string }, any>(
    '/v2/jobs',
    createJobMapper(createJobForm),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.jobKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createJob', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function updateJob(params: {
  updateJobForm: UpdateJobForm;
  jobKey: string;
}): Promise<GeneralApiResult> {
  const res = await apisauce.patch<void, any>(
    `/v2/jobs/${params.jobKey}`,
    updateJobMapper(params.updateJobForm),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}

export async function getJobs(options: {
  pagination: Pagination<JobsOrderByKey>;
  show: JobsFilterBy;
}): Promise<GeneralApiResultWithData<PartialJob[]>> {
  const res = await apisauce.get<PartialJobDto[], any>('/v2/jobs', {
    ...options.pagination,
    show: options.show,
  });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(partialJobMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getJobs', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function getJob(
  jobKey: string,
): Promise<GeneralApiResultWithData<Job>> {
  const res = await apisauce.get<JobDto, any>(`/v2/jobs/${jobKey}`);

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = await jobMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getJob', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function closeJob(jobKey: string): Promise<GeneralApiResult> {
  const res = await apisauce.put<void, any>(`/v2/jobs/${jobKey}/close`);

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}
