import {
  Box,
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  Button,
  Confirm,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  EmptyState,
  ExpandedQrCodeProvider,
  Icon,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  LeadingInputBox,
  PageContainer,
  PrintQrCode,
  ResponsiveBox,
  SearchInput,
  SolidIconButton,
  Spinner,
  Status,
  StatusProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  Text,
  Tooltip,
  TrailingInputBox,
  UpdatedAtTableCell,
  UpdatedByTableCell,
  paginatorOptions,
  parseTimestamp,
  useDebounce,
} from '@orbiapp/components';
import React from 'react';

import { assets } from '../../../../assets';
import { useDataGridPagination, usePauseOffer } from '../../../../helpers';
import {
  OffersOrderByKey,
  PartialOffer,
  PartialOfferDraft,
  offersSortableKeys,
} from '../../../../models';
import {
  CompanySelector,
  DeleteOfferDraftSelector,
  OfferDraftsSelector,
  OffersLinkSelector,
  OffersSelector,
  deleteOfferDraftThunk,
  getOfferDraftsThunk,
  getOffersLinkThunk,
  getOffersThunk,
  offersActions,
  useDispatch,
  useSelector,
} from '../../../../store';

const OFFERS_BREADCRUMBS: BreadcrumbListItem[] = [
  { to: '/offers', tx: 'label.breadcrumbs.offers.offers' },
];

const TABLE_COLUMN_WIDTHS = {
  title: 200,
  contactName: 200,
  startDate: 200,
  endDate: 200,
  status: 200,
  updatedBy: 200,
  updatedAt: 200,
  actions: 30,
};

const DeleteOfferDraftContext = React.createContext<
  React.Dispatch<React.SetStateAction<string | null>>
>(() => {});

function DeleteOfferDraftProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const deleteOfferDraftStatus = useSelector(
    DeleteOfferDraftSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const [offerDraftKey, setOfferDraftKey] = React.useState<string | null>(null);

  const deleteOfferDraft = async () => {
    if (!offerDraftKey) return;

    const res = await dispatch(deleteOfferDraftThunk(offerDraftKey));
    if (res.meta.requestStatus === 'fulfilled') {
      setOfferDraftKey(null);
    }
  };

  const cancelDeleteOfferDraft = () => {
    setOfferDraftKey(null);
  };

  return (
    <DeleteOfferDraftContext.Provider value={setOfferDraftKey}>
      <Confirm
        onConfirm={deleteOfferDraft}
        onCancel={cancelDeleteOfferDraft}
        isOpen={!!offerDraftKey}
        isLoading={deleteOfferDraftStatus === 'pending'}
        titleTx="prompt.delete-offer-draft.title"
        messageTx="prompt.delete-offer-draft.message"
        cancelTx="prompt.delete-offer-draft.cancel"
        confirmTx="prompt.delete-offer-draft.confirm"
      />

      {children}
    </DeleteOfferDraftContext.Provider>
  );
}

function getOfferStatusOptions(
  options:
    | {
        offerDraftKey: string;
      }
    | {
        disabledAt: number | null;
        endDate: number | null;
        startDate: number;
      },
): StatusProps {
  if ('offerDraftKey' in options) {
    return {
      variant: 'warning',
      tx: 'label.offers-status.draft',
    };
  }

  const now = Date.now();

  if (options.endDate && now > options.endDate) {
    return {
      variant: 'error',
      tx: 'label.offers-status.closed',
    };
  }

  if (options.disabledAt) {
    return {
      variant: 'error',
      tx: 'label.offers-status.paused',
    };
  }

  if (options.startDate > now) {
    return {
      variant: 'info',
      tx: 'label.offers-status.scheduled',
    };
  }

  return {
    variant: 'success',
    tx: 'label.offers-status.live',
  };
}

function SearchOffers() {
  const search = useSelector(OffersSelector.selectSearch);
  const pagination = useSelector(OffersSelector.selectPagination);

  const dispatch = useDispatch();

  const debounce = useDebounce();

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(offersActions.setOffersSearch(e.target.value));

    debounce(() => {
      if (!e.target.value) {
        dispatch(offersActions.clearOffers());
        dispatch(offersActions.setOffersSearch(''));
      }

      dispatch(getOffersThunk(pagination));
    });
  };

  const cancelSearch = () => {
    dispatch(offersActions.clearOffers());
    dispatch(offersActions.setOffersSearch(''));

    dispatch(getOffersThunk(pagination));
  };

  return (
    <Box width={300}>
      <SearchInput
        onChange={handleSearch}
        value={search}
        placeholderTx="placeholder.search-offers"
        leadingElement={
          <LeadingInputBox>
            <Icon name="magnifying-glass" />
          </LeadingInputBox>
        }
        trailingElement={
          <TrailingInputBox>
            {search.length > 0 && (
              <IconButton icon="x-mark" onClick={cancelSearch} />
            )}
          </TrailingInputBox>
        }
      />
    </Box>
  );
}

function OfferTableRow({ offer }: { offer: PartialOffer }) {
  const pauseOffer = usePauseOffer({
    endDate: offer.endDate,
    offerKey: offer.offerKey,
    startDate: offer.startDate,
  });

  return (
    <TableRow to={`/offers/${offer.offerKey}`}>
      <TableCell width={TABLE_COLUMN_WIDTHS.title} text={offer.title} />
      <TableCell
        width={TABLE_COLUMN_WIDTHS.contactName}
        text={offer.contactName}
      />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.startDate}
        text={parseTimestamp(offer.startDate, 'DD MMM YYYY HH:mm')}
      />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.endDate}
        text={
          offer.endDate
            ? parseTimestamp(offer.endDate, 'DD MMM YYYY HH:mm')
            : undefined
        }
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status
          {...getOfferStatusOptions({
            disabledAt: offer.disabledAt,
            endDate: offer.endDate,
            startDate: offer.startDate,
          })}
        />
      </TableCell>

      <UpdatedByTableCell
        fallbackTx="placeholder.unknown-user"
        editMeta={offer.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedBy}
      />

      <UpdatedAtTableCell
        editMeta={offer.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedAt}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.actions} hoverCell fixedRight>
        <Box ml="auto">
          {!pauseOffer.hidden && (
            <Tooltip
              placement="left"
              titleTx={
                offer.disabledAt
                  ? 'label.tooltip.resume'
                  : 'label.tooltip.pause'
              }
            >
              <IconButton
                isLoading={pauseOffer.isLoading}
                onClick={pauseOffer.toggleIsDisabled}
                icon={offer.disabledAt ? 'play-outline' : 'pause-outline'}
              />
            </Tooltip>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}

function renderOfferTableRow(partialOffer: PartialOffer) {
  return <OfferTableRow key={partialOffer.offerKey} offer={partialOffer} />;
}

function OfferDraftTableRow({ offerDraft }: { offerDraft: PartialOfferDraft }) {
  const setOfferDraftKey = React.useContext(DeleteOfferDraftContext);

  const deleteDraft: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();

    setOfferDraftKey(offerDraft.offerDraftKey);
  };

  return (
    <TableRow to={`/offers/create-offer/${offerDraft.offerDraftKey}`}>
      <TableCell
        width={TABLE_COLUMN_WIDTHS.title}
        text={offerDraft.title ?? undefined}
      />
      <TableCell
        width={TABLE_COLUMN_WIDTHS.contactName}
        text={offerDraft.contactName ?? undefined}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.startDate} />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.endDate}
        text={
          offerDraft.endDate
            ? parseTimestamp(offerDraft.endDate, 'DD MMM YYYY HH:mm')
            : undefined
        }
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status
          {...getOfferStatusOptions({
            offerDraftKey: offerDraft.offerDraftKey,
          })}
        />
      </TableCell>

      <UpdatedByTableCell
        fallbackTx="placeholder.unknown-user"
        editMeta={offerDraft.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedBy}
      />

      <UpdatedAtTableCell
        editMeta={offerDraft.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedAt}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.actions} hoverCell fixedRight>
        <Box ml="auto">
          <Tooltip placement="left" titleTx="label.tooltip.delete">
            <IconButton icon="trash-outline" onClick={deleteDraft} />
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
}

function renderOfferDraftTableRow(partialOfferDraft: PartialOfferDraft) {
  return (
    <OfferDraftTableRow
      key={partialOfferDraft.offerDraftKey}
      offerDraft={partialOfferDraft}
    />
  );
}

function OffersTable() {
  const offersStatus = useSelector(OffersSelector.selectStatus);
  const offersData = useSelector(OffersSelector.selectData);
  const offersPagination = useSelector(OffersSelector.selectPagination);

  const offersDrafts = useSelector(OfferDraftsSelector.selectData);
  const offerDraftsStatus = useSelector(OfferDraftsSelector.selectStatus);

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination<PartialOffer, OffersOrderByKey>({
      data: offersData,
      pagination: offersPagination,
      reset: offersActions.clearOffers,
      thunk: getOffersThunk,
    });

  const isLoading =
    offersStatus === 'pending' || offerDraftsStatus === 'pending';

  const isEmpty = rows.length === 0 && offersDrafts.length === 0;

  if (isLoading) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.offers.table.title" />
            <TableHead tx="label.offers.table.contact-name" />
            <TableHead tx="label.offers.table.start-date" />
            <TableHead tx="label.offers.table.end-date" />
            <TableHead tx="label.offers.table.status" />
            <TableHead tx="label.offers.table.updated-by" />
            <TableHead tx="label.offers.table.updated-at" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (isEmpty) {
    return (
      <EmptyState
        titleTx="label.offers.offers-empty-state.title"
        to="/offers/create-offer"
        buttonTx="label.offers.offers-empty-state.button"
      />
    );
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={offersPagination.orderBy}
          sortableColumns={Object.values(offersSortableKeys)}
          sortOrder={offersPagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.offers.table.title"
              name={offersSortableKeys.title}
            />
            <TableHead
              tx="label.offers.table.contact-name"
              name={offersSortableKeys.contactName}
            />
            <TableHead
              tx="label.offers.table.start-date"
              name={offersSortableKeys.startDate}
            />
            <TableHead
              tx="label.offers.table.end-date"
              name={offersSortableKeys.endDate}
            />
            <TableHead tx="label.offers.table.status" />
            <TableHead
              tx="label.offers.table.updated-by"
              name={offersSortableKeys.updatedBy}
            />
            <TableHead
              tx="label.offers.table.updated-at"
              name={offersSortableKeys.updatedAt}
            />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          {offersDrafts.map(renderOfferDraftTableRow)}
          {rows.map(renderOfferTableRow)}
        </TableBody>
      </Table>

      <TablePagination
        currentPage={paginatorProps.currentPage}
        hasNextPage={paginatorProps.hasNextPage}
        hasPrevPage={paginatorProps.hasPrevPage}
        onPageSizeChange={onPageSizeChange}
        onPaginate={onPaginate}
        pageSize={paginatorProps.pageSize}
        paginatorOptions={paginatorOptions}
        tx="label.general.rows-per-page"
      />
    </React.Fragment>
  );
}

function OffersContent() {
  const offersLinkStatus = useSelector(OffersLinkSelector.selectStatus);
  const offersLink = useSelector(OffersLinkSelector.selectData);

  const dispatch = useDispatch();

  const getQrCode = () => {
    dispatch(getOffersLinkThunk());
  };

  const isLoading = offersLinkStatus === 'pending';
  const isOpen = !!offersLink || isLoading;

  return (
    <React.Fragment>
      <Box flexJustify="between" flexWrap gap={16} flex>
        <Text
          color="pageTitle"
          variant="titleMd"
          tx="title.offers.dashboard"
          as="h1"
        />

        <Box flex flexWrap gap={16}>
          <ResponsiveBox
            xs={
              <Button
                variant="secondary"
                tx="button.offers.my-qr-code"
                onClick={getQrCode}
                isLoading={offersLinkStatus === 'pending'}
                disabled={isOpen}
              />
            }
          >
            <Tooltip placement="left" titleTx="button.offers.my-qr-code">
              <SolidIconButton
                onClick={getQrCode}
                icon="qr-code"
                disabled={isOpen}
              />
            </Tooltip>
          </ResponsiveBox>

          <ResponsiveBox
            xs={
              <Button
                to="/offers/create-offer"
                tx="button.offers.new-offer"
                variant="primary"
              />
            }
          >
            <Tooltip placement="left" titleTx="button.offers.new-offer">
              <SolidIconButton
                to="/offers/create-offer"
                icon="plus-circle-outline"
              />
            </Tooltip>
          </ResponsiveBox>
        </Box>
      </Box>

      <SearchOffers />

      <DeleteOfferDraftProvider>
        <OffersTable />
      </DeleteOfferDraftProvider>
    </React.Fragment>
  );
}

function OffersSidebar() {
  const offersLinkStatus = useSelector(OffersLinkSelector.selectStatus);
  const offersLink = useSelector(OffersLinkSelector.selectData);

  const isLoading = offersLinkStatus === 'pending';
  const isOpen = !!offersLink || isLoading;

  const dispatch = useDispatch();

  const closeSidebar = () => {
    dispatch(offersActions.clearOffersLink());
  };

  return (
    <ContentSidebar isOpen={isOpen} onClose={closeSidebar} width={400}>
      <ContentSidebarContentContainer>
        {isLoading && (
          <Box py={32} flex flexJustify="center">
            <Spinner />
          </Box>
        )}

        {!isLoading && (
          <Box flex flexDirection="column" gap={16}>
            <Box flex flexDirection="column" gap={4}>
              <Text
                tx="label.offers.qr-code-sidebar.title"
                variant="bodyLgBold"
              />
              <Text tx="label.offers.qr-code-sidebar.description" />
            </Box>

            <PrintQrCode
              expandTx="label.general.expand"
              printTx="label.general.print"
            />
          </Box>
        )}
      </ContentSidebarContentContainer>
    </ContentSidebar>
  );
}

export function Offers() {
  const offersLink = useSelector(OffersLinkSelector.selectData);
  const companyLogo = useSelector(CompanySelector.selectCompanyLogo);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getOfferDraftsThunk());
  }, [dispatch]);

  return (
    <PageContainer>
      <BreadcrumbsToolbar breadcrumbListItems={OFFERS_BREADCRUMBS} />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <OffersContent />
          </InnerContentContainer>
        </ContentContainer>

        <ExpandedQrCodeProvider
          footerLogoURL={assets.inAssociationWithOrbi}
          closeTx="button.close"
          value={offersLink ?? ''}
          logoURL={companyLogo?.thumbnail1024.url ?? ''}
          headerLogoURL={assets.redeemOffer}
        >
          <OffersSidebar />
        </ExpandedQrCodeProvider>
      </InnerPageContainer>
    </PageContainer>
  );
}
