import { ImageData, WorkspacesSessionStorage } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  Company,
  CreateCompanyForm,
  OfficeLocation,
  UpdateCompanyForm,
} from '../../models';
import { OrbiApi, v1, v2 } from '../../services';
import { getAccountThunk } from '../account';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import { OfficeLocationsSelector } from './company.selectors';
import { setupOfficeLocations } from './company.utils';

export const getCompanyThunk = createAsyncThunk<
  Company,
  undefined,
  ThunkApiConfig
>('company/getCompany', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v2.company.getCompany, undefined);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const createCompanyThunk = createAsyncThunk<
  string,
  CreateCompanyForm,
  ThunkApiConfig
>('company/createCompany', async (createCompany, thunkAPI) => {
  const state = thunkAPI.getState();
  if (!state.team.companyInvitation.data) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v1.company.createCompany, {
    ...createCompany,
    companyInvitationKey:
      state.team.companyInvitation.data.companyInvitationKey,
  });

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  WorkspacesSessionStorage.startSession(res.data);

  await thunkAPI.dispatch(getAccountThunk());
  await thunkAPI.dispatch(getCompanyThunk());

  return res.data;
});

export const updateCompanyThunk = createAsyncThunk<
  {
    updateCompany: UpdateCompanyForm;
    logo: ImageData;
  },
  UpdateCompanyForm,
  ThunkApiConfig
>('company/updateCompany', async (updateCompany, thunkAPI) => {
  const state = thunkAPI.getState();

  const res = await OrbiApi.call(v2.company.updateCompany, updateCompany);

  if (res.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('update-company:error'));
    return thunkAPI.rejectWithValue(res);
  }

  const newState = setupOfficeLocations(
    OfficeLocationsSelector.selectOfficeLocations(state),
    updateCompany.locations,
  );

  for (let i = 0; i < newState.length; i++) {
    const item = newState[i];

    switch (item.type) {
      case 'add':
        const createLocationRes = await OrbiApi.call(
          v2.company.createOfficeLocation,
          item.data,
        );

        if (createLocationRes.kind === 'ok') {
          updateCompany.locations.forEach((location, index) => {
            if (location.officeLocationKey === item.data.officeLocationKey) {
              updateCompany.locations[index].officeLocationKey =
                createLocationRes.data;
            }
          });
        }
        break;

      case 'delete':
        const deleteLocationRes = await OrbiApi.call(
          v2.company.deleteOfficeLocation,
          item.officeLocationKey,
        );
        if (
          deleteLocationRes.kind === 'bad-request' &&
          deleteLocationRes.message &&
          typeof deleteLocationRes.message === 'object'
        ) {
          updateCompany.locations.push(
            state.company.officeLocations.data.entities[item.officeLocationKey],
          );
        }
        break;

      case 'update':
        await OrbiApi.call(v2.company.updateOfficeLocation, item.data);
        break;
    }
  }

  thunkAPI.dispatch(setAlert('update-company:success'));
  return { updateCompany, logo: res.data.logo };
});

export const getOfficeLocationsThunk = createAsyncThunk<
  OfficeLocation[],
  undefined,
  ThunkApiConfig
>('company/getOfficeLocations', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const officeLocations = OfficeLocationsSelector.selectOfficeLocations(state);
  if (officeLocations.length) {
    return officeLocations;
  }

  const res = await OrbiApi.call(v2.company.getOfficeLocations, undefined);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});
