import { combineReducers } from '@reduxjs/toolkit';

import { accountReducer } from './account';
import { authReducer } from './auth';
import { companyReducer } from './company';
import { companyAdsReducer } from './company-ads';
import { companyProfileDraftsReducer } from './company-profile-drafts';
import { companyProfilesReducer } from './company-profiles';
import { connectReducer } from './connect';
import { countriesReducer } from './countries';
import { globalUiStateReducer } from './global-ui-state';
import { insightsReducer } from './insights';
import { jobsReducer } from './jobs';
import { locationsReducer } from './locations';
import { offersReducer } from './offers';
import { perksReducer } from './perks';
import { searchReducer } from './search';
import {
  getStateAfterEmulateUser,
  getStateAfterSignOut,
  getStateAfterSwitchWorkspace,
} from './store.reset';
import { subjectAreasReducer } from './subject-areas';
import { teamReducer } from './team';

const rootReducer = combineReducers({
  account: accountReducer,
  auth: authReducer,
  company: companyReducer,
  companyProfileDrafts: companyProfileDraftsReducer,
  companyProfiles: companyProfilesReducer,
  connect: connectReducer,
  globalUiState: globalUiStateReducer,
  insights: insightsReducer,
  jobs: jobsReducer,
  offers: offersReducer,
  perks: perksReducer,
  subjectAreas: subjectAreasReducer,
  team: teamReducer,
  companyAds: companyAdsReducer,
  locations: locationsReducer,
  search: searchReducer,
  countries: countriesReducer,
});

export const reducer: typeof rootReducer = (state, action) => {
  switch (action.type) {
    case 'auth/sign-out/fulfilled':
      return rootReducer(getStateAfterSignOut(state), action);
    case 'account/update-workspace/pending':
      return rootReducer(getStateAfterSwitchWorkspace(state), action);
    case 'auth/setIsEmulating':
      return rootReducer(getStateAfterEmulateUser(state), action);
  }

  return rootReducer(state, action);
};
