import { Time, reportWebVitals } from '@orbiapp/components';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './app';
import { ENV } from './config';
import './services/branch';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(quarterOfYear);

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: ENV,
  tracesSampleRate: ENV === 'production' ? 0.2 : 0,
  replaysOnErrorSampleRate: ENV === 'production' ? 1 : 0,
  replaysSessionSampleRate: ENV === 'production' ? 0.25 : 0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    Sentry.inboundFiltersIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      maskAttributes: ['password', 'credit_card'],
      minReplayDuration: Time.Minute,
      networkDetailAllowUrls: [/https:\/\/data.orbiapp.io\/.*/],
      networkRequestHeaders: ['Authorization', 'X-Orb-Company', 'X-Orb-Trace'],
    }),
    Sentry.replayCanvasIntegration(),
    Sentry.breadcrumbsIntegration(),
  ],
});

if (ENV === 'testing') {
  reportWebVitals(console.log);
}

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
