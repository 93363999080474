import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  OfferDraft,
  PartialOfferDraft,
  UpdateOfferDraft,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { OfferDraftDto, PartialOfferDraftDto } from './drafts.dto';
import {
  offerDraftMapper,
  partialOfferDraftMapper,
  updateOfferDraftMapper,
} from './drafts.mappers';

export async function createDraft(): Promise<GeneralApiResultWithData<string>> {
  const res = await apisauce.post<{ offerDraftKey: string }, any>(
    '/v1/offers/drafts',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.offerDraftKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createDraft', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function getDrafts(): Promise<
  GeneralApiResultWithData<PartialOfferDraft[]>
> {
  const res = await apisauce.get<PartialOfferDraftDto[], any>(
    '/v1/offers/drafts',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(partialOfferDraftMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getDrafts', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function getDraft(
  offerDraftKey: string,
): Promise<GeneralApiResultWithData<OfferDraft>> {
  const res = await apisauce.get<OfferDraftDto, any>(
    `/v1/offers/drafts/${offerDraftKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = await offerDraftMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getDraft', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function updateDraft(params: {
  offerDraftKey: string;
  updateOfferDraft: UpdateOfferDraft;
}): Promise<GeneralApiResult> {
  const res = await apisauce.patch<void, any>(
    `/v1/offers/drafts/${params.offerDraftKey}`,
    updateOfferDraftMapper(params.updateOfferDraft),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}

export async function deleteDraft(
  offerDraftKey: string,
): Promise<GeneralApiResult> {
  const res = await apisauce.delete<void, any>(
    `/v1/offers/drafts/${offerDraftKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}
