import { GeneralApiResultWithData } from '@orbiapp/components';

import { SubjectAreaCategory } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';
import { SubjectAreaCategoryDto } from './subject-area-categories.dto';
import { subjectAreaCategoryMapper } from './subject-area-categories.mappers';

export const getSubjectAreaCategories = async (): Promise<
  GeneralApiResultWithData<SubjectAreaCategory[]>
> => {
  const res = await apisauce.get<SubjectAreaCategoryDto[], any>(
    '/v2/subject-area-categories',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data
      .map(subjectAreaCategoryMapper)
      .sort((a, b) => a.name.localeCompare(b.name));

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getSubjectAreaCategories', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
