import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  LAYOUT_Z_INDEX,
  Menu,
  MenuButton,
  MenuItem,
  ProfileMenu,
  ProfileMenuHeader,
  ProfileMenuList,
  ProfileMenuListItem,
  ResponsiveBox,
  SelectWorkspaceMenu,
  SelectWorkspaceMenuItem,
  SidebarContext,
  Text,
  Time,
  Toolbar,
  ToolbarContentContainer,
  getAvatarVariantFromString,
  getPlacementMargin,
  numberFormatter,
  useAnchoredMenu,
  useCheckFeatureFlag,
  useCombinedRefs,
  useModalState,
} from '@orbiapp/components';
import React from 'react';

import { LEARN_MORE_ABOUT_WORKSPACES_URL } from '../../../constants';
import {
  AccountSelector,
  CompanySelector,
  ModuleSettingsSelector,
  useSelector,
} from '../../../store';
import { CreateProfileModal } from '../../create-profile-modal';

const QuickActionsContext = React.createContext<{
  createProfileModalState: ReturnType<typeof useModalState>;
}>({
  createProfileModalState: {
    closeModal: () => {},
    isOpen: false,
    openModal: () => {},
  },
});

function QuickActionsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const createProfileModalState = useModalState();

  return (
    <QuickActionsContext.Provider value={{ createProfileModalState }}>
      {children}
    </QuickActionsContext.Provider>
  );
}

interface ProfileMenuContextType extends ReturnType<typeof useAnchoredMenu> {
  anchorRef: React.RefObject<HTMLDivElement>;
  clickOutsideRef: React.RefObject<HTMLDivElement>;
  menuClickOutsideRef: React.RefObject<HTMLDivElement>;
}

const ProfileMenuContext = React.createContext<ProfileMenuContextType>({
  anchorRef: { current: null },
  clickOutsideRef: { current: null },
  closeMenu: () => {},
  isOpen: false,
  menuClickOutsideRef: { current: null },
  menuRef: { current: null },
  openMenu: () => {},
  toggleMenu: () => {},
});

function ProfileMenuProvider(props: React.PropsWithChildren) {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const clickOutsideRef = React.useRef<HTMLDivElement>(null);
  const menuClickOutsideRef = React.useRef<HTMLDivElement>(null);

  const profileMenuState = useAnchoredMenu({
    anchorRef,
    placement: 'bottom-end',
    autoCloseDuration: 5 * Time.Second,
    clickOutsideRef: [menuClickOutsideRef, anchorRef],
  });

  return (
    <ProfileMenuContext.Provider
      value={{
        ...profileMenuState,
        anchorRef,
        clickOutsideRef,
        menuClickOutsideRef,
      }}
      {...props}
    />
  );
}

function ToggleSidebarButton() {
  const { collapsed, toggleSidebar } = React.useContext(SidebarContext);

  return (
    <ResponsiveBox xs={null}>
      <IconButton
        icon={collapsed ? 'bars-3' : 'x-circle-outline'}
        onClick={toggleSidebar}
      />
    </ResponsiveBox>
  );
}

function CreateProfileMenuItem() {
  const { createProfileModalState } = React.useContext(QuickActionsContext);

  return (
    <MenuItem onClick={createProfileModalState.openModal}>
      <Text
        as="span"
        tx="label.quick-actions.create-profile"
        variant="bodyMd"
      />
    </MenuItem>
  );
}

function CreateNewProfileModal() {
  const { createProfileModalState } = React.useContext(QuickActionsContext);

  return (
    <CreateProfileModal
      isOpen={createProfileModalState.isOpen}
      closeModal={createProfileModalState.closeModal}
    />
  );
}

function CreateJobMenuItem() {
  const enableJobs = useSelector(ModuleSettingsSelector.selectEnableJobs);

  if (!enableJobs) {
    return null;
  }

  return (
    <MenuItem to="/jobs/create-job">
      <Text as="span" tx="label.quick-actions.create-job" variant="bodyMd" />
    </MenuItem>
  );
}

function CreateOfferMenuItem() {
  const enableOffers = useSelector(ModuleSettingsSelector.selectEnableOffers);

  if (!enableOffers) {
    return null;
  }

  return (
    <MenuItem to="/offers/create-offer">
      <Text as="span" tx="label.quick-actions.create-offer" variant="bodyMd" />
    </MenuItem>
  );
}

function QuickActionsMenu() {
  const enableJobs = useSelector(ModuleSettingsSelector.selectEnableJobs);
  const enableOffers = useSelector(ModuleSettingsSelector.selectEnableOffers);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const clickOutsideRef = React.useRef<HTMLDivElement>(null);

  const { closeMenu, isOpen, openMenu, menuRef } = useAnchoredMenu({
    anchorRef,
    placement: 'bottom',
    autoCloseDuration: 5 * Time.Second,
    clickOutsideRef,
  });

  const { createProfileModalState } = React.useContext(QuickActionsContext);

  const openModalAndCloseMenu = () => {
    createProfileModalState.openModal();
    closeMenu();
  };

  if (!enableJobs && !enableOffers) {
    return (
      <Button
        variant="primary"
        tx="button.profiles.create"
        onClick={openModalAndCloseMenu}
      />
    );
  }

  return (
    <ResponsiveBox
      xs={
        <Box ref={clickOutsideRef} minWidth={165}>
          <MenuButton
            isOpen={isOpen}
            onClick={openModalAndCloseMenu}
            onToggle={isOpen ? closeMenu : openMenu}
            ref={anchorRef}
            tx="label.quick-actions.create-profile"
          />

          <Menu
            isOpen={isOpen}
            ref={menuRef}
            minWidth="inherit"
            onClick={closeMenu}
            {...getPlacementMargin('bottom-start')}
          >
            <CreateProfileMenuItem />

            <CreateJobMenuItem />

            <CreateOfferMenuItem />
          </Menu>
        </Box>
      }
    />
  );
}

function ToggleUserProfileMenu() {
  const fullName = useSelector(AccountSelector.selectFullName);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const profilePicture = useSelector(AccountSelector.selectProfilePicture);

  const { anchorRef, isOpen, closeMenu, openMenu } =
    React.useContext(ProfileMenuContext);

  if (!fullName || !userKey) {
    return null;
  }

  return (
    <Avatar
      variant={getAvatarVariantFromString(userKey)}
      cursor="pointer"
      fallbackLetter={fullName[0]}
      highlight={isOpen}
      onClick={isOpen ? closeMenu : openMenu}
      ref={anchorRef}
      src={profilePicture?.thumbnail64.url ?? undefined}
    />
  );
}

function UserProfileMenu() {
  const fullName = useSelector(AccountSelector.selectFullName);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const email = useSelector(AccountSelector.selectEmail);

  const profilePicture = useSelector(AccountSelector.selectProfilePicture);

  const companyName = useSelector(CompanySelector.selectCompanyName);
  const companyLogo = useSelector(CompanySelector.selectCompanyLogo);

  const { menuRef, menuClickOutsideRef, isOpen, closeMenu } =
    React.useContext(ProfileMenuContext);

  const combinedRefs = useCombinedRefs(menuRef, menuClickOutsideRef);

  if (!fullName || !email || !isOpen || !userKey) {
    return null;
  }

  const avatarVariant = getAvatarVariantFromString(userKey);

  return (
    <ProfileMenu zIndex={LAYOUT_Z_INDEX.profileMenu} ref={combinedRefs}>
      <ProfileMenuHeader
        avatarVariant={avatarVariant}
        profilePictureSrc={profilePicture?.thumbnail64.url ?? ''}
        fullName={fullName}
        email={email}
      />

      <ProfileMenuList>
        <ProfileMenuListItem
          tx="label.toolbar-profile-menu.your-profile"
          icon="user-circle-outline"
          to="/settings/account"
          onClick={closeMenu}
        />

        <ProfileMenuListItem to="/settings/company" onClick={closeMenu}>
          <Avatar
            variant={avatarVariant}
            src={companyLogo?.thumbnail64.url ?? undefined}
            fallbackLetter={companyName?.charAt(0)}
            height={24}
            width={24}
          />
          <Text
            color="profileMenuListItemLabel"
            tx="label.toolbar-profile-menu.company-settings"
            variant="bodySm"
          />
        </ProfileMenuListItem>

        <ProfileMenuListItem
          tx="label.toolbar-profile-menu.team-settings"
          icon="users-outline"
          to="/settings/team"
          onClick={closeMenu}
        />

        <ProfileMenuListItem
          href="mailto:support@orbiapp.io"
          icon="question-mark-circle-outline"
          onClick={closeMenu}
          tx="label.toolbar-profile-menu.support"
        />

        <Divider />

        <ProfileMenuListItem
          tx="label.toolbar-profile-menu.sign-out"
          icon="power"
          to="/sign-out"
        />
      </ProfileMenuList>
    </ProfileMenu>
  );
}

export function SignedInToolbar() {
  const followerCount = useSelector(CompanySelector.selectFollowerCount);
  const companyName = useSelector(CompanySelector.selectCompanyName);
  const companyLogo = useSelector(CompanySelector.selectCompanyLogo);
  const companyKey = useSelector(CompanySelector.selectCompanyKey);

  const workspaces = useSelector(AccountSelector.selectSwitchableWorkspaces);

  const showSubtitle = useCheckFeatureFlag('workspace_menu_item_subtitle');

  const workspacesMenuItems = workspaces
    .filter((workspace) =>
      workspace.type === 'company' ? workspace.companyKey !== companyKey : true,
    )
    .map((workspace): SelectWorkspaceMenuItem => {
      switch (workspace.type) {
        case 'company':
          return {
            avatarSrc: workspace.logo.thumbnail64.url,
            avatarVariant: getAvatarVariantFromString(workspace.companyKey),
            label: workspace.name,
            switchTx: 'label.general.switch',
            subtitle: showSubtitle ? workspace.type : undefined,
            to: `/workspace/${workspace.type}/${workspace.companyKey}`,
          };

        default:
          return {
            avatarSrc: workspace.logo?.thumbnail64.url ?? '',
            avatarVariant: getAvatarVariantFromString(workspace.departmentKey),
            label: workspace.name,
            switchTx: 'label.general.switch',
            subtitle: showSubtitle ? workspace.type : undefined,
            to: `/workspace/${workspace.type}/${workspace.departmentKey}`,
          };
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <QuickActionsProvider>
      <ProfileMenuProvider>
        <Toolbar
          backgroundColor="toolbarBackground"
          zIndex={LAYOUT_Z_INDEX.pageToolbar}
        >
          <ToolbarContentContainer py={8}>
            <Box flex flexAlign="center" width="100%" gap={56}>
              <Box overflow="hidden" flexAlign="center" gap={16} flex>
                <ToggleSidebarButton />

                <SelectWorkspaceMenu
                  avatarSrc={companyLogo?.thumbnail64.url}
                  avatarVariant={getAvatarVariantFromString(companyKey ?? '')}
                  emptyStateLinkHref={LEARN_MORE_ABOUT_WORKSPACES_URL}
                  emptyStateLinkTarget="_blank"
                  emptyStateLinkTx="link.general.learn-more"
                  emptyStateTx="label.general.workspaces-menu-empty-state"
                  filterPlaceholderTx="placeholder.filter-workspaces"
                  label={companyName ?? ''}
                  workspaces={workspacesMenuItems}
                />
              </Box>

              <QuickAction />

              <Box ml="auto" flex gap={32} flexAlign="center">
                <ResponsiveBox
                  xs={
                    <Chip
                      variant="primary"
                      tx="label.general.followers"
                      whiteSpace="nowrap"
                      txArgs={{
                        followerCount: numberFormatter.format(
                          followerCount ?? 0,
                        ),
                      }}
                    />
                  }
                />

                <IconButton icon="cog-6-tooth-outline" to="/settings/company" />

                <ToggleUserProfileMenu />
              </Box>
            </Box>
          </ToolbarContentContainer>
        </Toolbar>

        <CreateNewProfileModal />

        <UserProfileMenu />
      </ProfileMenuProvider>
    </QuickActionsProvider>
  );
}

function QuickAction() {
  const enableProfiles = useSelector(
    ModuleSettingsSelector.selectEnableProfiles,
  );

  const enableOffers = useSelector(ModuleSettingsSelector.selectEnableOffers);

  if (enableProfiles) {
    return <QuickActionsMenu />;
  }

  if (enableOffers) {
    return (
      <Button
        to="/offers/create-offer"
        tx="button.offers.new-offer"
        variant="primary"
      />
    );
  }

  return null;
}
