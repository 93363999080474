import Joi from 'joi';

import {
  CreateCompanyQrSchema,
  UpdateCompanyQrSchema,
} from './company-qr.schema';

export const CreateCompanyQrValidation = Joi.object({
  name: CreateCompanyQrSchema.name,
  startDate: CreateCompanyQrSchema.startDate,
  description: CreateCompanyQrSchema.description,
});

export const UpdateCompanyQrValidation = Joi.object({
  name: UpdateCompanyQrSchema.name,
  description: UpdateCompanyQrSchema.description,
  startDate: UpdateCompanyQrSchema.startDate,
});
