import {
  LAYOUT_Z_INDEX,
  Sidebar,
  SidebarContext,
  SidebarItem,
} from '@orbiapp/components';
import { breakpoints } from '@orbiapp/theme';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { ModuleSettingsSelector, useSelector } from '../../store';

function SidebarMenuItems() {
  const enableProfiles = useSelector(
    ModuleSettingsSelector.selectEnableProfiles,
  );
  const enableJobs = useSelector(ModuleSettingsSelector.selectEnableJobs);
  const enableConnect = useSelector(ModuleSettingsSelector.selectEnableConnect);
  const enableCompanyAds = useSelector(
    ModuleSettingsSelector.selectEnableCompanyAds,
  );
  const enableOffers = useSelector(ModuleSettingsSelector.selectEnableOffers);

  const { closeSidebar } = React.useContext(SidebarContext);

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (window.innerWidth < breakpoints.sm) {
      closeSidebar();
    }
  }, [pathname, closeSidebar]);

  return (
    <React.Fragment>
      <SidebarItem
        icon="rectangle-group-outline"
        iconActive="rectangle-group-solid"
        isActive={pathname.includes('/insights')}
        to="/insights"
        tooltipTx="link.sidebar.insights"
        tx="link.sidebar.insights"
      />

      {enableProfiles && (
        <SidebarItem
          icon="user-circle-outline"
          iconActive="user-circle-solid"
          isActive={pathname.includes('/profiles')}
          to="/profiles"
          tooltipTx="link.sidebar.profiles"
          tx="link.sidebar.profiles"
        />
      )}

      {enableJobs && (
        <SidebarItem
          icon="briefcase-outline"
          iconActive="briefcase-solid"
          isActive={pathname.includes('/jobs')}
          to="/jobs"
          tooltipTx="link.sidebar.jobs"
          tx="link.sidebar.jobs"
        />
      )}

      {enableConnect && (
        <SidebarItem
          icon="connect"
          iconActive="connect"
          isActive={pathname.includes('/connect')}
          to="/connect"
          tooltipTx="link.sidebar.connect"
          tx="link.sidebar.connect"
        />
      )}

      {enableCompanyAds && (
        <SidebarItem
          icon="bolt-outline"
          iconActive="bolt-solid"
          isActive={pathname.includes('/company-ads')}
          to="/company-ads"
          tooltipTx="link.sidebar.company-ads"
          tx="link.sidebar.company-ads"
        />
      )}

      {enableOffers && (
        <SidebarItem
          icon="tag-outline"
          iconActive="tag-solid"
          isActive={pathname.includes('/offers')}
          to="/offers"
          tooltipTx="link.sidebar.offers"
          tx="link.sidebar.offers"
          height={40}
        />
      )}
    </React.Fragment>
  );
}

export function MainSidebar() {
  return (
    <Sidebar
      zIndex={LAYOUT_Z_INDEX.pageSidebar}
      backdropZIndex={LAYOUT_Z_INDEX.pageSidebarBackdrop}
    >
      <SidebarMenuItems />
    </Sidebar>
  );
}
