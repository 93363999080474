import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  JobDraft,
  PartialJobDraft,
  UpdateJobDraftForm,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { JobDraftDto, PartialJobDraftDto } from './drafts.dto';
import {
  jobDraftMapper,
  partialJobDraftMapper,
  updateJobDraftMapper,
} from './drafts.mappers';

export async function createDraft(): Promise<GeneralApiResultWithData<string>> {
  const res = await apisauce.post<{ jobDraftKey: string }, any>(
    '/v2/jobs/drafts',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.jobDraftKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createDraft', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function getDrafts(): Promise<
  GeneralApiResultWithData<PartialJobDraft[]>
> {
  const res = await apisauce.get<PartialJobDraftDto[], any>('/v2/jobs/drafts');

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(partialJobDraftMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getDrafts', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function getDraft(
  jobDraftKey: string,
): Promise<GeneralApiResultWithData<JobDraft>> {
  const res = await apisauce.get<JobDraftDto, any>(
    `/v2/jobs/drafts/${jobDraftKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = await jobDraftMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getDraft', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function updateDraft(
  updateDraftForm: UpdateJobDraftForm,
): Promise<GeneralApiResult> {
  const res = await apisauce.patch<void, any>(
    `/v2/jobs/drafts/${updateDraftForm.jobDraftKey}`,
    updateJobDraftMapper(updateDraftForm),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}

export async function deleteDraft(
  jobDraftKey: string,
): Promise<GeneralApiResult> {
  const res = await apisauce.delete<void, any>(
    `/v2/jobs/drafts/${jobDraftKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}
